import { Box, Container, CssBaseline, Link, Typography } from '@mui/material'
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Code from './pages/code';
import Home from './pages/home';

function Copyright(props: any) {
  const url = `${window.location.protocol}//${window.location.hostname}`;
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href={url}>
        {url}
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const theme = createTheme();

function App() {
  return <>
    <ThemeProvider theme={theme}>
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'stretch',
        }}
      >
        <img src="/Logo.png" alt="Please don't posion me logo" />
        <BrowserRouter>
          <Routes>
            <Route path="/code" element={<Code />} />
            <Route index element={<Home />} />
          </Routes>
        </BrowserRouter>
        <Copyright />
      </Box>
    </Container>
    </ThemeProvider>
  </>;
}

export default App;
