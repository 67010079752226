import { Box, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { Decode } from "../shared/codeGenerator";


const Code = () => {
    const queryParams = new URLSearchParams(window.location.search);
    const code = queryParams.get("code");

    if(code === null) {
        return <>No code</>
    }

    const diet = Decode(code);

    const rows: string[] = [];
    if(diet.vegetarian){
        rows.push("Vegetarian");
    }
    if(diet.vegan){
        rows.push("Vegan");
    }
    if(diet.gluten){
        rows.push("Gluten intolerant");
    }
    if(diet.lactose){
        rows.push("Lactose intolerant");
    }
    if(diet.peanuts){
        rows.push("Peanut allergy");
    }
    if(diet.corn){
        rows.push("Corn allergy");
    }
    if(diet.seafood){
        rows.push("Seafood allergy");
    }
    if(diet.alcohol){
        rows.push("Alcohol allergy");
    }
    if(diet.redmeat){
        rows.push("Red Meat allergy");
    }

    return <>
        <Box marginY={2}>
            <Typography marginBottom={0.5}>My dietary requirements are:</Typography>
            {rows.map((value) => <div>{value}</div>)}
        </Box>

        <Typography marginBottom={2}>Create your own <Link to="/">"Please Don't Poison Me"</Link> page.</Typography>
    </>
}

export default Code;