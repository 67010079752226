

export interface dietData {
    vegetarian: boolean;
    vegan: boolean;
    gluten: boolean; //Intolerance
    lactose: boolean; //Intolerance
    peanuts: boolean; //Allergy
    corn: boolean; //Allergy
    seafood: boolean; //Allergy
    alcohol: boolean; //Allergy
    redmeat: boolean; //Allergy
  }

export const Encode = (options: dietData): string => {
    let result = 0;
    result = result | (options.vegetarian ? 1 : 0)
    result = result | (options.vegan ? 2 : 0);
    result = result | (options.gluten ? 4 : 0);
    result = result | (options.lactose ? 8 : 0);
    result = result | (options.peanuts ? 16 : 0);
    result = result | (options.corn ? 32 : 0);
    result = result | (options.seafood ? 64 : 0);
    result = result | (options.alcohol ? 128 : 0);
    result = result | (options.redmeat ? 256 : 0);
    result = result | 4096;
    // 1,2,4,8|16,32,64,128|256,512,1024,2048|4096
    return result.toString(16).slice(1);
}

export const Decode = (code: string): dietData => {
    const result = parseInt(code, 16);
    return {
        vegetarian: (result & 1) === 1,
        vegan: (result & 2) === 2,
        gluten: (result & 4) === 4,
        lactose: (result & 8) === 8,
        peanuts: (result & 16) === 16,
        corn: (result & 32) === 32,
        seafood: (result & 64) === 64,
        alcohol: (result & 128) === 128,
        redmeat: (result & 256) === 256
    }
}