import { Typography, Box, FormControlLabel, Checkbox, Button } from "@mui/material"
import { useState } from "react";
import { Decode, Encode } from "../shared/codeGenerator";

const CodeSection = (props: { code: string}) => {
    const url = `${window.location.protocol}//${window.location.hostname}/code?code=${props.code}`;
    return <Typography variant="body2" align="center" marginBottom={2}>
        {url} <Button onClick={() => { window.navigator.clipboard.writeText(url)}}>Copy</Button>
    </Typography>;
}

const Home = () => {
    const savedCode = window.localStorage.getItem("code");
    const saved = savedCode !== null ? Decode(savedCode) : undefined;
    const [code, setCode] = useState<string | undefined>(savedCode === null ? undefined : savedCode)

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      const data = new FormData(event.currentTarget);
  
      const newCode = Encode({
        gluten: data.get('gluten') === 'true' ? true : false,
        vegan: data.get("vegan") === 'true' ? true : false,
        vegetarian: data.get('vegetarian') === 'true' ? true : false,
        lactose: data.get('lactose') === 'true' ? true : false,
        seafood: data.get('seafood') === 'true' ? true : false,
        peanuts: data.get('peanuts') === 'true' ? true : false,
        corn: data.get('corn') === 'true' ? true : false,
        alcohol: data.get('alcohol') === 'true' ? true : false,
        redmeat: data.get('redmeat') === 'true' ? true : false
      })
      console.log(newCode);
      setCode(newCode);
      window.localStorage.setItem("code", newCode)
    }

    return <>
        <Box component="form" onSubmit={handleSubmit} noValidate marginY={2}>
          <div>
            <FormControlLabel
              control={<Checkbox name="vegetarian" value="true" color="primary" defaultChecked={saved?.vegetarian} />}
              label="Vegetarian"
            />
          </div>
          <div>
            <FormControlLabel
              control={<Checkbox name="vegan" value="true" color="primary" defaultChecked={saved?.vegan} />}
              label="Vegan"
            />
          </div>
          <div>
            <FormControlLabel
              control={<Checkbox name="gluten" value="true" color="primary" defaultChecked={saved?.gluten} />}
              label="Gluten intolerant"
            />
          </div>
            <FormControlLabel
              control={<Checkbox name="lactose" value="true" color="primary" defaultChecked={saved?.lactose} />}
              label="Lactose intolerant"
            />
          <div>
            <FormControlLabel
              control={<Checkbox name="seafood" value="true" color="primary" defaultChecked={saved?.seafood} />}
              label="Seafood allergy"
            />
          </div>
          <div>
            <FormControlLabel
              control={<Checkbox name="peanuts" value="true" color="primary" defaultChecked={saved?.peanuts} />}
              label="Peanut allergy"
            />
          </div>
          <div>
            <FormControlLabel
              control={<Checkbox name="corn" value="true" color="primary" defaultChecked={saved?.corn} />}
              label="Corn allergy"
            />
          </div>
          <div>
            <FormControlLabel
              control={<Checkbox name="alcohol" value="true" color="primary" defaultChecked={saved?.alcohol} />}
              label="Alcohol allergy"
            />
          </div>
          <div>
            <FormControlLabel
              control={<Checkbox name="redmeat" value="true" color="primary" defaultChecked={saved?.redmeat} />}
              label="Red meat allergy"
            />
          </div>
        <Button type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}>Get Code</Button>
        </Box>

        {code !== undefined && <CodeSection code={code} />}
    </>
}

export default Home;